import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent'; // Import CardContent
import Button from '@mui/material/Button'; // Import Button
import Container from '@mui/material/Container';
import games from '../data/games.js';
import Typography from '@mui/material/Typography';

function GameList() {
  return (
    <Container maxWidth="lg">
         <Typography variant="h2" textAlign="center" gutterBottom>
Our picks          </Typography>
          <Typography variant="h6" textAlign="center" marginBottom="2rem">
    At the core of our mission lies a deep-seated love for gaming. It's what drives us every day to enhance, innovate, and bring you the most thrilling gaming experiences. From the latest hits to timeless classics, we're here to ensure your gaming adventure is nothing short of extraordinary. Are you ready to unleash the gamer in you and explore worlds beyond imagination? Join us on this exciting journey and let's create unforgettable gaming moments together.
</Typography>
       
      <Grid container spacing={4}>
        {games.map((game) => (
          <Grid item xs={6} sm={4} md={3} key={game.id}>
            <Card
              sx={{
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                }, backgroundColor: "secondary.main"
              }}
            >
              <CardActionArea component={Link} to={`/game/${game.id}`}>
                <CardMedia
                  component="img"
                  alt={game.title}
                  height="180"
                  image={game.image}
                  title={game.title}
                  style={{ fontSize: '14px' }}
                />
                <CardContent>
                  <Typography variant="h6" component="div" color="primary.main300">
                    {game.title}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/game/${game.id}`}
                  >
                    Play for Free
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default GameList;
