import { Hero, GamesList, AgeDisc, CTA, Benefits, Contacts, CookieModal } from "./components";
import { ThemeProvider } from '@mui/material';
import theme from "./theme";

function App() {
  return (
    <div className="App">
<ThemeProvider theme={theme}>
      <Hero/>
      <GamesList/>
      <AgeDisc/>
      <CookieModal/>
      </ThemeProvider>
    </div>
  );
}

export default App;
