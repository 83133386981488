import React from 'react';
import Container from "@mui/material/Container";
import Header from './Header';
import Typography from '@mui/material/Typography';

export default function About() {
  return (
    <>
    <Header/>
    <Container>
    <Typography variant="h4" gutterBottom>
  Welcome to Zencpulse - The Ultimate Destination for Free Gaming Excellence!
</Typography>

<Typography variant="body1" paragraph>
  Zencpulse isn't just another place to play games online; it's a universe of unparalleled gaming adventures awaiting gamers of every type. From those who seek a quick escape to hardcore gamers looking for their next challenge, we offer a treasure trove of gaming delights.
</Typography>

<Typography variant="body1" paragraph>
  Step into our realm of endless possibilities, where you'll find a curated selection of free online games. Our library is constantly expanding, featuring everything from the simplicity and nostalgia of arcade classics to the intricate storytelling and immersive worlds of modern adventures. Each game is a gateway to a new experience, designed to captivate with stunning visuals and captivating gameplay.
</Typography>

<Typography variant="body1" paragraph>
  At Zencpulse, we believe that the joy of gaming should be accessible to everyone. That's why our platform is completely free to use, with no hidden charges or pay-to-win barriers. Our community thrives on the joy of gaming without the worry of costs, allowing you to dive deep into your favorite games or explore new genres without any strings attached.
</Typography>

<Typography variant="body1" paragraph>
  What sets Zencpulse apart is not just our extensive collection of games but also our dedication to creating a vibrant, welcoming community of gamers. Engage in multiplayer battles, share your high scores, or exchange gaming tips and tricks with fellow enthusiasts from around the globe. Our platform is not only about playing games but also about celebrating the gaming culture in all its forms.
</Typography>

<Typography variant="body1" paragraph>
  Discover the latest gems and hidden treasures in our ever-growing catalogue of free online games. From pulse-pounding action games to mind-bending puzzles, strategy games that test your intellect, and heartwarming casual games, Zencpulse has it all. Every visit brings a new opportunity to explore, compete, and connect.
</Typography>

<Typography variant="body1" paragraph>
  Your journey at Zencpulse is bound to be filled with exciting discoveries, new friendships, and unforgettable gaming experiences. With fresh content added regularly and special events to keep the excitement alive, we promise a gaming platform that evolves with you, offering endless entertainment without ever asking for a dime.
</Typography>

<Typography variant="body1" paragraph>
  Embrace the spirit of free gaming today at Zencpulse. Whether you're looking to kill some time, find a new hobby, or engage with a community of like-minded gamers, we're here to ensure your gaming adventure is second to none. Let the games begin!
</Typography>
    </Container>
      </>
  )
}
