import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Form from "./Form";
import Header from "./Header";
import { contactUs } from "../assets";

export default function Contacts() {
  return (
    <div>
      <Header />
      <Container maxWidth="lg" id="contacts">
      <Typography variant="h2" textAlign="center" marginBottom="2.5rem">
      Contact information
        </Typography>  <Typography variant="h4" textAlign="center" gutterBottom>
        40 Elephant Rd, London SE17 1EU, United Kingdom
        </Typography>  <Typography variant="h4" textAlign="center" gutterBottom>
        +442072774777
        </Typography>
         <Typography variant="h4" textAlign="center" marginBottom="2.5rem">
        info@zencpulse.com
        </Typography>
        <Typography variant="h3" textAlign="center" marginBottom="2.5rem">
          Have Any Questions?
        </Typography>
        <Grid
          container
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          marginTop="2rem"
        >
          <Grid item xs={12} sm={6}>
            <Form />
          </Grid>
          <Grid item xs={12} sm={6} mx="auto">
            <img
              src={contactUs}
              alt=""
              style={{ maxWidth: "350px", width: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
