// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { heroBg } from "../assets";
import Grow from '@mui/material/Grow';

function Hero() {
  return (
    <Box
      height="70vh"
      display="flex"
      flexDirection="column"
      style={{
        background: `radial-gradient(circle, rgba(22,6,13,0.5480786064425771) 0%, rgba(14,14,1,0.3604035364145658) 100%), url(${heroBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "right",
      }}
    >
        <Header />
        <Container maxWidth="xl">
        <Grow in={true} timeout={1200}>

          <Box p={4} textAlign="center">
            <Typography variant="h3" gutterBottom style={{fontWeight: "900"}}>
            Unleash Your Inner Gamer <br/> With Free Online Games
            </Typography>
            <Typography variant="h4">
             Embark on an Epic Gaming Adventure
            </Typography>
          </Box>
          </Grow>

        </Container>
    </Box>
  );
}

export default Hero;
