import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {ctaBg} from "../assets";
import NoMoney from "./NoMoney";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      zencpulse {new Date().getFullYear()}{'.'}
    </Typography>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: 'auto',
        }} style={{
          background: `linear-gradient(150deg, rgba(0,0,0,.8) 40%, rgba(0,0,0,.8) 55%), url(${ctaBg})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center",
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1">
          <Link to="/contacts"  style={{color: "#ffffff", textDecoration: "none"}}>
              Contacts
            </Link>{' '}
            |{' '}
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy
            </Link>
          </Typography>
          <Copyright />
          <NoMoney/>
        </Container>
      </Box>
  );
}
