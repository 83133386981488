import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#080806',
      main: '#0E0F0C',
      main600: '#121310',
      main500: '#161713',
      main400: '#191A16',
      main300: '#3C3C39',
      main200: '#5E5F5C',
      main100: '#E3E4E3'
    },
    text: {
      primary: '#ffffff',
    },
    secondary: {
      main: '#c5f296', 
      secondary: '#CFCF00'
    },
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Righteous, sans-serif', 
    color: "white",
    h3: {
      fontSize: '1.8rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    },
    h4: {
      fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
